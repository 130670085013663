.react-tel-input .country-list .country {
  text-align: left;
}

.react-tel-input .country-list .country-name {
  color: #6b6b6b;
}

.react-tel-input .country-list .country-name:hover,
.react-tel-input .country-list .country .dial-code:hover {
  background: transparent;
}

.react-tel-input .form-control {
  height: 42px;
  width: 100%;
  border-radius: 8px;
}

.react-tel-input .country-list .search-box:hover {
  background: #fff;
  color: #6b6b6b;
}

.phone {
  border-color: green !important;
}
