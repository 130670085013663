.authForm {
  margin-top: 20% !important;
}
select {
  background: transparent !important;
  color: #7d7d7e !important;
}

.sort select {
  background: #dddddd !important;
}
textarea {
  resize: none;
  width: 100%;
  height: auto !important;
  min-height: 100px;
  max-height: 125px;
  border: 1px solid var(--gray-1);
  border-radius: 8px;
  padding: 15px;
  font-size: 15px;
  color: #171717;
  background-color: white;
  margin-bottom: 2.25rem !important;
}
.widthIsh {
  width: 99.5% !important;
}
.widthIsh1 {
  width: 80% !important;
}
.thumbnail {
  max-width: 70px !important;
  height: 80px !important;
}
.box1 {
  width: 99% !important;
}
.box {
  width: 99% !important;
}
.firstRContainer2 {
  width: 93% !important;
  display: flex;
  position: absolute;
  background: #fff;
  min-height: 400px;
  border-radius: 16px;
}
.cardPro {
  max-width: 31% !important;
  min-width: 31% !important;
  margin: 1% !important;
  width: 100%;
}
.anyS {
  margin-left: -10px !important;
}
.chart_memberDiv {
  width: 97% !important;
  margin-left: 11px !important;
  display: flex;
  height: auto;
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;

  width: 100%;
}
.rowStats1 {
  flex: 1.68 !important;
}

.bonD {
  width: 30% !important;
}
.profile-actions-container {
  margin-right: 1.5% !important;
}
.viewP {
  width: 360px !important ;
}
.cardDivG {
  margin-left: 5px !important;
}
.termsI {
  width: 25%;
}
.statusAlert{
  background: #171717;
    padding: 1%;
    /* margin-bottom: -15px; */
    text-align: center;
    color: white;
   font-size: 1rem;

}
.statusAlert button{
  border: 8px solid #ff385c;
    background: #ff385c;
    margin-left: 1%;
    color: white;
    cursor: pointer;
}

@media (max-width: 40em) {
  .statusAlert{
    background: #171717;
    padding: 3% 5% 7%;
    margin-bottom: -15px;
    text-align: center;
    color: white;
    font-size: 1rem;
  }
  .termsI {
    width: 65%;
  }
  .cardDivG {
    margin-left: 0px !important;
  }

  .profile-actions-container {
    margin-right: 1.5% !important;
  }
  .box {
    width: 99% !important;
  }
  .widthIsh1 {
    width: 100% !important;
  }
  .box1 {
    width: 100% !important;
  }
  .widthIsh {
    width: 100% !important;
  }
  .keehXT {
    width: 100% !important;
    height: 120px;
    border-radius: 8px;
    background: var(--white);
    border: 1px solid rgba(4, 4, 6, 0.1);
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px;
    gap: 2.5rem;
    position: relative;
  }

  .gbyWmG {
    -webkit-flex: 1.65 !important;
    -ms-flex: 0.65 !important;
    flex: 1.65 !important;
    height: 380px;
    background-color: #fff;
    border-radius: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .kwhPxv {
    width: 100%;
  }
  .css-18k9sie-MuiTypography-root {
    margin: 0;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 1.7;
    color: #171717;
    font-family: inter, Roboto, sans-serif;
    color: #7d7d7e;
    text-align: center !important;
    padding: 12px;
  }
  .css-rzdc00-MuiTypography-root {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.7;
    color: #171717;
    font-family: inter, Roboto, sans-serif;
    font-size: 14px !important;
  }
  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    overflow: hidden;
    margin-left: 20px !important;
  }

  .viewP {
    position: absolute;
    background-color: var(--white);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
    z-index: 999;
    width: 342px;
    margin-left: -237px !important;
    margin-top: 45px;
    min-height: 150px;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgba(4, 4, 6, 0.1);
    padding: 1rem;
  }

  .notiV {
    margin-top: 6px;
    top: 6% !important;
    position: absolute;
    width: 100% !important;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgba(4, 4, 6, 0.1);
    min-height: 100px;
    background-color: var(--white);
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 1rem;
    left: 8% !important;
  }
  .lhUnsp .actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 0rem !important;
  }
  .lhUnsp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
    width: 100%;
    padding: 20px 20px 0px !important;
  }
  .kwhPxv {
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100% !important;
  }
  .makeStyles-metaForm-5 {
    width: 100%;
    display: flex;
    position: absolute;
    background: #fff;
    margin-top: 36px;
    border-radius: 16px;
    flex-direction: column !important;
  }
  .makeStyles-metaForm-5 {
    width: 100% !important;
    display: flex;
    position: absolute;
    background: #fff;
    margin-top: 36px;
    min-height: 400px;
    border-radius: 16px;
  }
  .makeStyles-bottom-4 {
    flex: 0.83;
    margin-top: -120px;
    margin-left: 0px !important;
    margin-right: 3.5rem;
  }
  .eNDqrB {
    -webkit-flex: 0.8;
    -ms-flex: 0.8;
    flex: 0.8;
    height: auto;
    min-height: 40vh;
    background-color: var(--white);
    border-left: 1px dotted var(--gray-1);
    padding: 1rem !important;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .ehilqr {
    -webkit-flex: 0.2;
    -ms-flex: 0.2;
    flex: 0.2;
    padding: 1rem !important;
    margin-top: 2rem;
  }
  .makeStyles-container-1 {
    width: 100%;
    height: auto;
    display: flex;
    background: #f5f5f5;
    min-height: 160vh !important;
    flex-direction: column;
  }
  label {
    white-space: nowrap !important;
  }

  .dPnEWA form .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 1rem !important;
  }
  .makeStyles-arrowIconWrapper-4 {
    top: 5rem;
    left: 252px;
    color: #fff;
    width: 25px;
    border: 1px solid #fff;
    cursor: pointer;
    height: 25px;
    display: flex;
    z-index: 1102;
    position: fixed;
    background: #262626;
    transition: left 0.25s ease-in-out;
    align-items: center;
    border-radius: 0.5rem;
    justify-content: center;
    display: none !important;
  }
  .gIdIDY {
    width: 100% !important;
    height: 120px;
    border-radius: 8px;
    background: var(--white);
    border: 1px solid rgba(4, 4, 6, 0.1);
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px;
    gap: 2.5rem;
    position: relative;
  }
  .dSZWte .title h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: var(--main-bg);
    margin-left: 6% !important;
  }
  .clFqMr {
    margin-top: 6px;
    top: 2.3% !important;
    position: absolute;
    width: 380px;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgba(4, 4, 6, 0.1);
    min-height: 100px;
    background-color: var(--white);
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    left: 0% !important;
  }
  .eXIuhu {
    position: absolute;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    z-index: 999;
    width: 342px;
    margin-left: -244px !important;
    margin-top: 45px;
    min-height: 150px;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgba(4, 4, 6, 0.1);
    padding: 1rem;
  }
  .ivbcnJ {
    margin-left: 38px;
    width: 68% !important;
    height: 40px;
    border-radius: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--white);
    border: 1px solid rgba(4, 4, 6, 0.1);
  }
  .search-export {
    width: 100% !important  ;
    padding: 15px 0px;
    height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: nowrap !important;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px dotted var(--gray-1);
  }
  .export {
    width: 100px;
    height: 40px;
    border-radius: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--white);
    gap: 1rem;
    padding: 9px;
    cursor: pointer;
    margin-top: -2.5% !important;
  }
  .export {
    width: 30%;
    height: 40px;
    border-radius: 8px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--white);
    gap: 1rem;
    padding: 9px;
    cursor: pointer;
    margin-right: 27px;
    margin-left: 15px;
  }
  .ivbcnJ {
    margin-left: 54px !important;
    width: 330px;
    height: 40px;
    border-radius: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--white);
    border: 1px solid rgba(4, 4, 6, 0.1);
    margin-right: 2% !important;
  }
  .makeStyles-metaForm-15 {
    width: 100% !important;
    display: flex;
    position: absolute;
    background: #fff;
    margin-top: 36px;
    min-height: 400px;
    border-radius: 16px;
    flex-direction: column !important;
    margin-left: -13% !important;
  }

  .hSBfSw {
    -webkit-flex: 0.8;
    -ms-flex: 0.8;
    flex: 0.8;
    height: auto;
    min-height: 40vh;
    background-color: var(--white);
    border-left: 1px dotted var(--gray-1);
    padding: 2rem;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-top: 0px !important;
  }

  .bonD {
    width: 100% !important;
  }
  .rowStats {
    width: 100% !important;
  }

  .rowStats1 {
    width: 100% !important;
  }
  .title h2 {
    margin-left: 5% !important;
  }
  .actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 0rem !important;
  }

  .makeStyles-allProperties-12 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-around;
    margin-bottom: 15% !important;
  }

  .proDiv {
    flex-direction: column !important;
    margin-left: 0% !important;
    width: 100% !important;
    margin-left: -14% !important;
  }
  .proDiv nav {
    -webkit-flex: 0.2;
    -ms-flex: 0.2;
    flex: 0.2;
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: -45px !important;
  }
  .prodivSec {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-top: 0px !important;
  }

  .proModel {
    width: 100% !important;
    height: 100vh;
    background-color: #fff;
    overflow: scroll;
    overflow-x: hidden;
    padding: 16px;
    /* padding-left: 100px; */
    padding-right: 32px;
    padding-bottom: 24px;
    -webkit-transition: all 1s ease-in;
    transition: all 1s ease-in;
  }
  .cardPro {
    min-width: 340px !important;
    width: 100% !important;
  }
  .search-export div {
    width: 100% !important;
  }
  .search-export div input {
    width: 41% !important;
    height: 25px;
    border: none;
    outline: none;
    border-radius: 8px;
    padding-right: 10px;
    color: var(--main-bg);
  }
  .emptyMinText {
    font-size: 0.8rem !important;
  }

  .mobNav {
    color: gray !important;
    margin: 16% !important;
    background: fff !important;
    padding: 8% !important;
    font-size: 3rem !important;
    border-radius: 5px !important;
    border: 1.5px solid #e6e6e6;
  }
  .marginTop {
    margin-top: 25% !important;
  }
  .dMREmP .search-filter-export {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .search-filter-export {
    padding-top: 15px;
    padding-bottom: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: nowrap !important;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px dotted var(--gray-1);
  }

  .filter-export .filter {
    margin-right: 10px;
    width: 40%;
    height: 40px;
    border-radius: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--white);
    gap: 1rem;
    padding: 9px;
    cursor: pointer;
    margin-top: -7px !important;
  }
  .search-filter-export > div {
    margin-bottom: 10px;
    width: 42% !important;
  }
  .search-filter-export div input {
    width: 45% !important;
  }
  .filter-export .filter {
    padding: 9px;
    cursor: pointer;
    margin-right: -7px !important;
  }
  .search-filter-export {
    margin-left: -5%;
  }
  .search-sort {
    width: 100%;
  }

  .searchSI {
    /* margin-left: 54px !important; */
    width: 96% !important;
    height: 40px;
    border-radius: 8px;
    display: flex;
    padding: 10px;
    align-items: center;
    background-color: var(--white);
    border: 1px solid rgba(4, 4, 6, 0.1);
    margin-right: 5% !important;
  }

  .sort {
    margin-right: 93px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    padding: 9px;
    cursor: pointer;
    width: 100% !important;
    margin-left: 16px;
  }
  .bxrCMP {
    margin-left: -273px !important;
  }
  .propertyHeading {
    margin-left: 16px !important;
  }
  .anyS {
    width: 50% !important;
    margin-left: 12% !important;
    margin-top: 3%;
  }

  .anySI {
    width: 36% !important;
    height: 25px;
    border: none;
    outline: none;
    border-radius: 8px;
    padding-right: 10px;
    color: var(--main-bg);
  }
  .authForm {
    margin-top: 30% !important;
  }

  .firstRContainer {
    flex-direction: column;
  }
  .jCHJTf {
    -webkit-flex: 0.8;
    -ms-flex: 0.8;
    flex: 0.8;
    height: auto;
    min-height: 40vh;
    background-color: var(--white);
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 2rem;
  }
  .dxlqnS h3 {
    color: var(--main-black);
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    margin-left: 16px !important;
    margin-top: 50px !important;
    text-align: left;
  }

  h2 {
    color: var(--main-black);
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    margin-top: 25px !important;
    text-align: left !important;
    margin-left: 16px !important;
  }
  h5 {
    text-align: left !important;
  }
  .QTRCM h5 {
    text-align: left !important;
  }

  h5 {
    text-align: left !important;
  }

  .subText {
    text-align: left !important;
  }
  .subTextD {
    margin-left: 0px !important;
  }
  .notiV {
    margin-top: 6px;
    top: 6% !important;
    position: absolute;
    width: 100% !important;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgba(4, 4, 6, 0.1);
    min-height: 100px;
    background-color: var(--white);
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 1rem;
    left: 0% !important;
  }
  .notiV1 {
    margin-top: 6px;
    top: 2.4% !important;
    position: absolute;
    width: 100% !important;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgba(4, 4, 6, 0.1);
    min-height: 100px;
    background-color: var(--white);
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 1rem;
    left: 0% !important;
    margin-left: auto !important;
  }
  #msgTip {
    width: 80% !important;
    margin-left: 12%;
    padding: 2%;
  }

  .firstRContainer {
    flex-direction: column;
    width: 100% !important;
  }
  .firstRContainer2 {
    margin-left: -13.9% !important;
  }
  form .proceed {
    margin-bottom: 1rem !important;
  }
  .firstRContainer2 {
    margin-left: -13.9% !important;
  }
  .rNav {
    margin-bottom: -11%;
  }
  .subText {
    text-align: left !important;
    margin-left: 5% !important;
  }
  select {
    padding: 5px !important;
  }
  .proTextE {
    margin: 0;
    font-size: 12px !important;

    text-align: center !important;
    padding: 12px;
  }
  .cardPro {
    max-width: 371px !important;
    min-width: 93% !important;
    margin-left: 17.1px !important;
    width: 100% !important;
  }
}
.subText {
  text-align: left !important;
}

td {
  white-space: nowrap;
}

th {
  white-space: nowrap;
}

form .row {
  gap: 1rem !important;
}

.proceed {
  margin-bottom: 10px !important;
}

.carousel-container {
  position: relative;
  margin: auto;
  margin-top: -3%;
}

.carousel-image {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}

.box {
  margin: 0px !important;
}

h6 {
  color: var(--white);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
label {
  font-weight: 500 !important;
}

form .row .gender .select-container select {
  font-size: 14px !important;
}

form .profile-pic .upload button {
  font-size: 14px !important;
}
button {
  border-radius: 8px !important;
}

.letter-drawable {
  min-width: 40px !important;
}

.cardPro {
  border-radius: 8px !important;
  box-shadow: none !important;
}

.rowStats > span {
  font-size: 25px !important;
}

.row .invite-link {
  word-break: break-all;
}

ul li {
  list-style-type: disc;
}

ol li {
  list-style-type: decimal;
}


ul {
  font-size: 1.5rem;
}


.SA {
  padding: 0 5px;
  width: 100%;
  height: 29px;
  padding: 0px 5px;
  text-transform: lowercase;

  /* left: 1292px; */
  /* top: 331px; */
  background: rgba(30, 198, 119, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: #1ec677;
}
.SIA {
  width: 100%;
  height: 29px;
  /* left: 1292px; */
  /* top: 331px; */
  padding: 0px 5px;
  text-transform: lowercase;

  background: rgba(248, 146, 15, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: #f8920f;
}

.box2{
  width: 50%;
  margin: 0 auto;
  
}